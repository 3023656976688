#
# Controllers / actions specific javascript
#

import Vue from 'vue/dist/vue.esm'
import mapboxgl from 'mapbox-gl'
import Dropzone from 'dropzone'
import flatpickr from 'flatpickr'
import lottie from 'lottie-web'
import Plyr from 'plyr'
require 'slick-carousel'
require 'tooltipster'

$.extend true, $.qs, {

  controllers:

    studios:

      index_act: ->
        $.qs.controllers.commons.address_autocomplete()
        $.qs.controllers.commons.locate()

        $('#studio_search_name').change (e) -> $(e.currentTarget).parents('form').submit();
        $('.panel-apply').click (e) -> $(e.currentTarget).parents('form').submit();

        list   = $('#studios-list')
        canvas = $('#map')

        if mapboxgl.supported()
          map = new mapboxgl.Map(
            container: canvas.get(0),
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [list.data('lng'), list.data('lat')], zoom: 8
          )
          mapPopup = new mapboxgl.Popup({ offset: 15, closeButton: false, closeOnClick: false })

        studioList = new Vue {
          el: '#studios-list'
          data: {
            studios: []
          }
          computed: {
            visibleStudios: -> @studios.filter((studio) -> studio.visible)
            results: -> I18n.t('js.results', { count: @visibleStudios.length })
          }
          created: ->
            fetch(list.data('url'))
              .then((response) -> response.json())
              .then((json) =>
                @studios = json
                return unless mapboxgl.supported

                for studio in @studios
                  popup = new mapboxgl.Popup({ offset: 15 }).setText(studio.name)
                  $.qs.controllers.commons.studioMarker(studio).setLngLat([studio.lng, studio.lat]).setPopup(popup).addTo(map)

                if @studios.length == 1
                  map.jumpTo center: [@studios[0].lng, @studios[0].lat], zoom: 15
                else if @studios.length > 1
                  bounds = new mapboxgl.LngLatBounds()
                  for studio, i in @studios
                    break if i >= 20 || (i >= 3 && studio.distance > 50)
                    bounds.extend [studio.lng, studio.lat]

                  map.fitBounds bounds, padding: { top: 25, bottom: 25, left: 25, right: 25 }
              )
        }

        if mapboxgl.supported()
          map.on 'moveend', ->
            bounds = map.getBounds()
            for studio in studioList.studios
              studio.visible = (studio.lng - bounds._ne.lng) * (studio.lng - bounds._sw.lng) < 0 &&
                               (studio.lat - bounds._ne.lat) * (studio.lat - bounds._sw.lat) < 0


        canvas.on 'mouseover', '[data-studio-id]', (e) ->
          studio = studioList.studios.find((s) -> s.id == parseInt($(e.currentTarget).attr('data-studio-id')))
          mapPopup.setLngLat([studio.lng, studio.lat]).setHTML("<strong>#{studio.name}</strong>").addTo(map) if studio

        canvas.on 'mouseout', '[data-studio-id]', (e) -> mapPopup.remove();

        canvas.on 'click', '[data-studio-id]', (e) ->
          studio = studioList.studios.find((s) -> s.id == parseInt($(e.currentTarget).attr('data-studio-id')))
          Turbolinks.visit studio.url if studio

        $('#studios-list').on 'mouseover', '[data-studio-id]', (e) ->
          canvas.find("[data-studio-id=\"#{$(e.currentTarget).attr('data-studio-id')}\"]").addClass 'clicked'

        $('#studios-list').on 'mouseout', '[data-studio-id]', (e) ->
          canvas.find("[data-studio-id=\"#{$(e.currentTarget).attr('data-studio-id')}\"]").removeClass 'clicked'

        # Range slider
        # $('input[type="range"].distance-radius').rangeslider
        #   polyfill : false
        #   onInit : ->
        #     this.output = $('<div class="range-output" />').insertBefore(this.$range).html(this.$element.val())
        #     $('.range-output').after "<i class=\"data-radius-title\">#{$('.distance-radius').data('title')}</i>"
        #   onSlide : (position, value) -> this.output.html(value)

        $.qs.controllers.commons.bookmark()

      show_act: ->
        # Photo slider
        $('.listing-slider').slick {
          centerMode: true, centerPadding: '20%', slidesToShow: 2,
          responsive: [
            { breakpoint: 1367, settings: { centerPadding: '15%' } },
            { breakpoint: 1025, settings: { centerPadding: '0' } },
            { breakpoint: 767,  settings: { centerPadding: '0', slidesToShow: 1 } }
          ]
        }

        # Map : load it only when it reach the viewport
        if IntersectionObserver?
          io = new IntersectionObserver((entries) ->
            entries.forEach((entry) ->
              if (entry.isIntersecting)
                $.qs.controllers.commons.map()
                io.unobserve(entry.target))
          , { rootMargin: '50px' })
          io.observe($('#listing-location').get(0))
        else
          $.qs.controllers.commons.map()

        $.qs.controllers.commons.bookmark()

        $('#booking-date').flatpickr(locale: I18n.locale, dateFormat: 'd/m/Y', minDate: Date.now(), maxDate: Date.now() + 86400 * 1000 * $('#booking-date').data('period'))

        $('[data-fb-share]').click (e) -> !window.open(e.currentTarget.href, 'Facebook', 'width=640,height=580')

      bookmarks_act: -> $.qs.controllers.commons.bookmark()

    bookings:

      index_act: ->
        $('#change-date').flatpickr(locale: I18n.locale, dateFormat: 'd/m/Y', minDate: Date.now(), maxDate: Date.now() + 86400 * 1000 * $('#date').data('period'),\
                                    wrap: true, onChange: (selectedDates, dateStr, instance) -> Turbolinks.visit "#{instance.input.dataset.url}?date=#{dateStr}")

        $('.room .available').click (e) ->
          room_id = $(e.currentTarget).parents('.room').data('room')
          d = e.currentTarget.dataset
          Turbolinks.visit "#{$('#rooms').data('url')}?room_id=#{room_id}&start=#{d.start}&end=#{d.end}"

        $('i.sl-icon-question').each ->
          $(@).tooltipster position: 'right', content: $(@).next('.description'), contentCloning: true

      new_act: ->
        $('#booking_form_band_id').change (e) ->
          url = new URL(window.location.toString())
          url.searchParams.set 'band_id', $(e.target).val()
          Turbolinks.visit url.toString()

        $('[data-refresh-summary]').change (e) ->
          f = $(e.target).parents('form')
          $.get f.data('summary'), f.serialize(), (data) -> $('div.summary').replaceWith(data)

      create_act: -> $.qs.controllers.bookings.new_act()

    passes:
      new_act: ->
        $('[data-refresh-summary]').change (e) ->
          f = $(e.target).parents('form')
          $.get f.data('summary'), f.serialize(), (data) -> $('div.summary').replaceWith(data)

      create_act: -> $.qs.controllers.passes.new_act()

    blog_posts:
      show_act: ->
        $('[data-fb-share]').click (e) -> !window.open(e.currentTarget.href, 'Facebook', 'width=640,height=580')

    pages:

      index_act: ->
        $.qs.controllers.commons.address_autocomplete()
        $.qs.controllers.commons.locate()

        anim_n = $('.anim-container')
        lottie.setQuality('low') if $('body').hasClass('mobile')
        lottie.loadAnimation(
          container: anim_n[0], loop: true, autoplay: true, prerender: true,
          renderer: anim_n.data('anim-renderer'), path: anim_n.data('anim-file')
        )

      map_act: -> $.qs.controllers.commons.map()

      professional_act: ->
        $('.testimonial-carousel').slick {
          centerMode: true, centerPadding: '34%', slidesToShow: 1, dots: true, arrows: false,
          responsive: [
            { breakpoint: 1025, settings: { centerPadding: '10px', slidesToShow: 2 } },
            { breakpoint: 767,  settings: { centerPadding: '10px', slidesToShow: 1 } }
          ]
        }

      overview_video_act: ->
        player = new Plyr('video', { controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'] });
        player.on 'ended', (e) ->
          player.fullscreen.exit()
          $('[data-video-actions]').show()
        $('[data-replay]').click (e) ->
          e.preventDefault()
          $('[data-video-actions]').hide()
          player.restart()
          player.play()

      contact_us_act: ->
        display_form = (s) ->
          $('[data-contact]').toggle($(s).val() > 2 || $(s).val() == '')
          $('[data-studios]').toggle($(s).val() <= 2)
        $('#request').change (e) -> display_form(e.currentTarget)
        display_form('#request')

    account:

      studios:

        index_act: -> $.qs.controllers.commons.bookmark()

    commons:

      bookmark: ->
        $('#main-content').on 'click', '.like-icon, .widget-button, .like-button', (e) ->
          e.preventDefault()
          $(e.currentTarget).toggleClass('liked')
          $(e.currentTarget).children('.like-icon').toggleClass('liked')
          $.ajax $(e.target).data('url'), { method: 'PATCH', data: { bookmark: $(e.currentTarget).hasClass('liked') }}

      # Google places address autocomplete
      address_autocomplete: ->
        autocompleteNode = $('[data-field="location"]')
        formSubmit       = autocompleteNode.data('submit') || false
        autocompleteNode.keydown (e) ->
          if e.keyCode == 13
            e.preventDefault()
            $(e.target).val('').blur()
        autocompleteNode.change ->
          if autocompleteNode.val() is ''
            $('[data-field="lat"]').val ''
            $('[data-field="lng"]').val ''
            autocompleteNode.parents('form').submit() if formSubmit
        autocomplete = new google.maps.places.Autocomplete autocompleteNode.get(0), { componentRestrictions: { country:  ['fr', 'be', 'ch', 'mc', 'lu'] } }
        google.maps.event.addListener autocomplete, 'place_changed', ->
          place = autocomplete.getPlace()
          return if !place.geometry

          $('[data-field="lat"]').val place.geometry.location.lat()
          $('[data-field="lng"]').val place.geometry.location.lng()
          $('[data-field="lnglat_rand"]').val place['types']? && place['types'].indexOf('locality') > -1
          autocompleteNode.parents('form').submit() if formSubmit

      # Init a Mapbox Map with a location marker
      map: ->
        canvas   = $('#singleListingMap')
        position = [canvas.data('lng'), canvas.data('lat')]

        map      =  new mapboxgl.Map(
          container: canvas.get(0),
          style: 'mapbox://styles/mapbox/streets-v11',
          center: position, zoom: 16
        )

        map.addControl(new mapboxgl.NavigationControl())
        map.scrollZoom.disable()
        $.qs.controllers.commons.mapboxMarker("im im-icon-Electric-Guitar").setLngLat(position).addTo(map)

        map


      # Get location
      locate: ->
        $('[data-locate]').click (e) ->
          e.preventDefault()

          unless navigator.geolocation
            $.qs.flashMessage.create I18n.t('js.locate_error'), 'alert'
            return

          navigator.geolocation.getCurrentPosition (p) ->
            geocoder = new google.maps.Geocoder
            geocoder.geocode { location: { lat: p.coords.latitude, lng: p.coords.longitude} }, (results, status) ->
              return unless status is 'OK'
              place = results[0]
              $('[data-field="location"]').val place.formatted_address
              $('[data-field="lat"]').val place.geometry.location.lat()
              $('[data-field="lng"]').val place.geometry.location.lng()
              $('[data-field="location"]').parents('form').submit() if $('[data-field="location"]').length > 0

      # Custom Mapbox marker
      mapboxMarker: (iconClasses) ->
        marker = document.createElement('div')
        marker.className = 'map-marker-container'
        marker.innerHTML =  """
                           <div class="marker-container">
                               <div class="marker-card">
                                 <div class="front face"><i class="#{iconClasses}"></i></div>
                                 <div class="back face"><i class="#{iconClasses}"></i></div>
                                 <div class="marker-arrow">
                               </div>
                             </div>
                             <div class="overlay"></div>
                           </div>
                           """
        new mapboxgl.Marker(marker)

      studioMarker: (studio) ->
        cl = switch studio.kind
          when 'recording' then 'im im-icon-Mixer'
          when 'dance hall' then 'im im-icon-Ballet-Shoes'
          else 'im im-icon-Electric-Guitar'
        marker = @mapboxMarker(cl)
        marker.getElement().setAttribute 'data-studio-id', studio.id
        marker

      # Google Map custom marker
      # CustomMarker: class extends google.maps.OverlayView
        # constructor: (latlng, map, markerIco, options={}) ->
        #   @node      = null
        #   @latlng    = latlng
        #   @markerIco = markerIco
        #   @options   = options
        #   @setMap map

        # draw: ->
        #   unless @node
        #     @node = document.createElement('div')
        #     @node.className = 'map-marker-container'
        #     @node.innerHTML =  """
        #                       <div class="marker-container">
        #                           <div class="marker-card">
        #                             <div class="front face">#{@markerIco}</div>
        #                             <div class="back face">#{@markerIco}</div>
        #                             <div class="marker-arrow">
        #                           </div>
        #                         </div>
        #                         <div class="overlay"></div>
        #                       </div>
        #                       """
        #     google.maps.event.addDomListener @node, 'click', => google.maps.event.trigger @, 'click'
        #     @node.dataset.markerid = @options.markerid if @options['markerid']
        #     @getPanes().overlayImage.appendChild @node

        #   point = @getProjection().fromLatLngToDivPixel(@latlng)
        #   if point
        #     @node.style.left = "#{point.x}px"
        #     @node.style.top  = "#{point.y}px"

        # remove: ->
        #   if @node
        #     @node.remove()
        #     @node = null

        # getPosition: -> @latlng

      # Add custom zoom buttons to a google map
      # options: alignLeft: true
      # mapZoomButtons: (map, options={}) ->
      #   zc = $.parseHTML  """
      #               <div class="#{'zoomControlWrapper' if options['alignLeft']}" style="padding: 5px;">
      #                 <div><div class="custom-zoom-in"></div><div class="custom-zoom-out"></div></div>
      #               </div>
      #               """
      #   map.controls[google.maps.ControlPosition.RIGHT_CENTER].push zc[0]
      #   google.maps.event.addDomListener $(zc).find('.custom-zoom-in')[0],  'click', -> map.setZoom(map.getZoom() + 1)
      #   google.maps.event.addDomListener $(zc).find('.custom-zoom-out')[0], 'click', -> map.setZoom(map.getZoom() - 1)

  # maps:
  #   styles:
  #     orange: [{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"23"}]},{"featureType":"poi.attraction","elementType":"geometry.fill","stylers":[{"color":"#f38eb0"}]},{"featureType":"poi.government","elementType":"geometry.fill","stylers":[{"color":"#ced7db"}]},{"featureType":"poi.medical","elementType":"geometry.fill","stylers":[{"color":"#ffa5a8"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#c7e5c8"}]},{"featureType":"poi.place_of_worship","elementType":"geometry.fill","stylers":[{"color":"#d6cbc7"}]},{"featureType":"poi.school","elementType":"geometry.fill","stylers":[{"color":"#c4c9e8"}]},{"featureType":"poi.sports_complex","elementType":"geometry.fill","stylers":[{"color":"#b1eaf1"}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":"100"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"100"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffd4a5"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffe9d2"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"weight":"3.00"}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"weight":"0.30"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"36"}]},{"featureType":"road.local","elementType":"labels.text.stroke","stylers":[{"color":"#e9e5dc"},{"lightness":"30"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":"100"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#d2e7f7"}]}]

}, true
